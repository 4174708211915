import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题

// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace= function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("@/views/Login.vue")
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import("@/views/Register.vue")
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import("@/views/Index.vue")
    },
    {
        path: "/rebackPassword",
        name: "RebackPassword",
        component: () => import("@/views/RebackPassword.vue")
    },
    {
        path: "/stepPassword",
        name: "StepPassword",
        component: () => import("@/views/StepPassword.vue")
    },
    {
        path: "/resetPasswd",
        name: "ResetPasswd",
        component:() => import("@/views/ResetPasswd.vue")
    }
]

const router = new VueRouter({
    routes,
    mode: "history"
})

// 检查是否存在于免登陆白名单
function inWhiteList(toPath) {
    const whiteList = ['/login', '/rebackPassword', '/stepPassword', '/register', '/404','/wxsign.html']
    const path = whiteList.find((value) => {
        // 使用正则匹配
        const reg = new RegExp('^' + value)
        return reg.test(toPath)
    })
    return !!path
}

router.beforeEach((to, from, next) => {
    const token = Cookies.get("token")
    if (inWhiteList(to.path)) {
        next()
    } else {
        //用户已登录
        if (token) {
            next()
        } else {
            next(`/login`)
        }
    }
})

export default router
