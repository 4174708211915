import axios from 'axios'
import router from "@/router";
import ElementUI from "element-ui";
import Cookies from 'js-cookie'

const request = axios.create({
    baseURL: 'http://localhost:8090/freshidapp',
    timeout: 30000,
    withCredentials:true    //设置跨域携带cookie
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    //config.headers['Content-Type'] = 'application/json;charset=utf-8';
    //let token = Cookies.get("token") ? Cookies.get("token") : null
    let token = localStorage.getItem("token") ? localStorage.getItem("token") : ''
    if (token) {
        config.headers['Authorization'] ="bearer " + token;  // 设置请求头
    }

    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        //兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            console.log("服务端返回的字符串：",res)
        }
        // 当权限验证不通过的时候给出提示
        if (res.code === 401) {
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
            router.replace("/login")
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default request
